import RealmRepresentation from "@keycloak/keycloak-admin-client/lib/defs/realmRepresentation";
import {
  createNamedContext,
  useEnvironment,
  useErrorBoundary,
  useFetch,
  useRequiredContext,
} from "@keycloak/keycloak-ui-shared";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { useAdminClient } from "../../admin-client";
import {
  DashboardRouteWithRealm,
  DashBoardRouteWithRealmsParam,
  toDashboard,
} from "../../dashboard/routes/Dashboard";
import { i18n } from "../../i18n/i18n";
import { fetchAdminUI } from "../auth/admin-ui-endpoint";

type RealmContextType = {
  realm: string;
  realmRepresentation?: RealmRepresentation;
  refresh: () => void;
};

export const RealmContext = createNamedContext<RealmContextType | undefined>(
  "RealmContext",
  undefined,
);

export const RealmContextProvider = ({ children }: PropsWithChildren) => {
  const { adminClient } = useAdminClient();
  const { keycloak, environment } = useEnvironment();
  const [key, setKey] = useState(0);
  const refresh = () => setKey(key + 1);
  const [realmRepresentation, setRealmRepresentation] =
    useState<RealmRepresentation>();

  // --- DA Redirect /realms/:realm to /:realm -------------------------------
  const routeContainsRealmsParam = window.location.href.includes("/realms/");
  const routeMatch = useMatch({
    path: routeContainsRealmsParam
      ? DashBoardRouteWithRealmsParam.path
      : DashboardRouteWithRealm.path,
    end: false,
  });
  // --- END DA Redirect /realms/:realm to /:realm ----------------------------

  // --- DA Redirect unauthorized user ----------------------------------------
  type RealmNameRepresentation = {
    name: string;
    displayName?: string;
  };

  const [allRealms, setAllRealms] = useState<RealmNameRepresentation[]>([]);
  useFetch(
    async () => {
      try {
        return await fetchAdminUI<RealmNameRepresentation[]>(
          adminClient,
          "ui-ext/realms/names",
        );
      } catch (error) {
        return [];
      }
    },
    setAllRealms,
    [],
  );

  const navigate = useNavigate();
  const { showBoundary } = useErrorBoundary();

  const getRealm = useCallback(() => {
    const isAdmin = keycloak.tokenParsed?.["is-master-admin"];
    const isMasterRealmEnv = environment.realm === "master";
    const isMasterRealmRoute =
      !routeMatch || routeMatch.params.realm === "master";

    if (
      allRealms.length > 0 &&
      isMasterRealmRoute &&
      isMasterRealmEnv &&
      !isAdmin
    ) {
      const realm = allRealms[0].name;
      navigate(toDashboard({ realm }));

      // Remove the error boundary that was triggered when the user was trying
      // to access the master realm.
      showBoundary();
    }

    // If the user can view current realm, return it.
    return routeMatch?.params.realm || environment.realm;
  }, [routeMatch, keycloak.tokenParsed, allRealms, environment.realm]);

  const realm = getRealm();
  // --- END DA Redirect unauthorized user ------------------------------------

  // Configure admin client to use selected realm when it changes.
  useEffect(() => {
    (async () => {
      adminClient.setConfig({ realmName: realm });
      const namespace = encodeURIComponent(realm);
      await i18n.loadNamespaces(namespace);
      i18n.setDefaultNamespace(namespace);
    })();
  }, [realm]);
  useFetch(
    () => adminClient.realms.findOne({ realm }),
    setRealmRepresentation,
    [realm, key],
  );

  return (
    <RealmContext.Provider value={{ realm, realmRepresentation, refresh }}>
      {children}
    </RealmContext.Provider>
  );
};

export const useRealm = () => useRequiredContext(RealmContext);
